/* eslint-disable @typescript-eslint/no-var-requires */

import api from "@/api";
import { smsSubjectActivityMemberParam } from "@/api/modules/signUp";
import BreadCrumbs from "@/components/BreadCrumbs";
import { arrayBufferToBase64, debounceClick, parseTime, sharer } from "@/utils";
import { ElFormItemContext } from "element-plus/lib/el-form";
import { Options, setup, Vue } from "vue-class-component";
import { SmsSubjectAndChapterDto, SmsSubjectChapterDtos, SmsSubjectTutor } from "../detail";
import './index.scss'
import { isEmail } from '@/utils/validate'
import { commitT, MetaRow } from "@/models/type";
import { ElMessage, ElNotification } from "element-plus";
import { ButtonHTMLAttributes } from "vue";
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters } from "vuex";
import { UserLevel } from "@/store/modules/user";
import { useMeta } from "vue-meta";
import axios from "axios";
import { getToken } from "@/utils/auth";
const QRCode = require('qrcodejs2')

@Options({
  computed: {
    ...mapGetters(['GetUserLevel'])
  }
})
export default class SubjectSignup extends Vue {
  public GetUserLevel?: UserLevel

  public loading = false

  public chapterList: Array<SmsSubjectChapterDtos> = []
  public smsSubjectObj: SmsSubjectAndChapterDto = {}
  public tutorObj: SmsSubjectTutor = {}

  public subjectForm: smsSubjectActivityMemberParam = {
    memberName: '',
    cardId: '',
    phone: '',
    email: '',
    type: 'subject',
    targetId: 0
  }
  public type = ''

  public detailObj: any = {
    payType: ''
  }

  public timer: any = {}
  public detailRow: any = {}

  public payList: any[] = [
    {title: '微信支付', value: 2},
    {title: '支付宝支付', value: 1},
    {title: 'PayMe支付', value: 5},
    {title: 'VISA支付', value: 3},
    {title: 'Master支付', value: 4},
  ]

  public show = false
  public payImg = ''

  metaList: Array<MetaRow> = []

  meta = setup(() => {
    const { meta } = useMeta({
      meta: this.metaList
    })
    return meta
  })


  emailValidate = (rules: any, value: string, cb: commitT) => {
    if(!value) {
      cb(new Error('请输入电邮'))
    } else if (!isEmail(value)) {
      cb(new Error('请输入正确邮箱'))
    } else {
      cb()
    }
  }

  public elements: any = {}
  public stripe: any = {}
  public items: any = {}

  public return_id = 0
  public qrCodeUrl = ''

  public payLoading = false

  formRules: any = {
    memberName: [
      {required: true, message: '请输入姓名'}
    ],
    cardId: [
      {required: true, message: '请输入身份证'}
    ],
    phone: [
      {required: true, message: '请输入手机号'}
    ],
    email: [
      {required: true, validator: this.emailValidate}
    ]
  }

  getMeta(): void {
    this.metaList.splice(0, this.metaList.length)
    this.metaList.push(
      {property: 'og:title', content: this.smsSubjectObj.title as string},
      {property: 'og:description', content: this.smsSubjectObj.introduce as string},
      {property: 'og:image', content: this.smsSubjectObj.coverImg as string}
    )
  }

  getDetail(): void {
    api.sso_info()
    const id = this.$route.query?.id as never
    this.subjectForm.targetId = id
    api.getSubjectById(id).then(res => {
      this.chapterList = res.data.chapter
      this.smsSubjectObj = res.data.smsSubject
      this.tutorObj = res.data.tutor
      this.getMeta()
    })
    const get_id = this.$route.query?.get_id as never
    if(get_id) {
      const price = this.$route.query?.price as never
      this.detailRow.id = +get_id
      this.detailRow.payPrice = price
      this.type = 'detail'
    }
  }

  submit(): void {
    const __form = this.$refs.form as ElFormItemContext
    __form.validate(v => {
      if(v) {
        this.loading = true
        const obj = Object.assign({}, this.subjectForm)
        const discount = this.GetUserLevel?.discount
        obj.price = this.smsSubjectObj.price as string
        api.signUpCallBack(obj).then(res => {
          if(discount) {
            this.detailRow = res.data
            ElNotification({
              type: 'success',
              title: '成功',
              message: '报名成功，请支付报名订单'
            })
            this.loading = false
            this.type = 'detail'
          } else {
            ElNotification({
              type: 'success',
              title: '成功',
              message: '报名成功'
            })
            setTimeout(() => {
              this.$router.push({
                name: 'MineSubject'
              })
            }, 1000)
          }
        }).catch(() => this.loading = false)
      } else {
        return false
      }
    })
  }

  goBack(): void {
    if(document.body.clientWidth < 900) {
      // TODO  跳转的mobile
      window.location.href = `http://mobile.taosseract.com/pages/subject/signup?id=${this.subjectForm.targetId}`
    } else {
      this.type = ''
    }
  }

  choosePay(value: number): void {
    this.detailObj.payType = value
  }

  detailDom(): JSX.Element {
    return <div
      style={{
        padding: '40px'
      }}
    >
      <div class="subject-signup-header" onClick={this.goBack}>
        <div>
          <i class="el-icon-arrow-left"/>
          <span>返回</span>
        </div>
      </div>
      <div class="subject-signup-pay">
        {this.payList.map((item, index) => <div
          class={[
            'pay-list',
            this.detailObj.payType === item.value ? 'pay-list-active' : 'pay-list-primary'
          ]}
          onClick={() => this.choosePay(item.value)}
        >
          <div class={[`pay-list-img_${index}`]} />
          <div>{item.title}</div>
        </div>)}
      </div>
      <div class="subject-signup-bottom">
        <div>实付款：</div>
        <div>HK$</div>
        <div>{this.detailRow.payPrice}</div>
      </div>
      <div class="subject-signup-btn">
        {
          this.detailObj.payType
            ? <div class="pointer" onClick={this.pay}>
              {this.payLoading && <i class="el-icon-loading" style="margin-right:10px"/>}
              <span>确认付款</span>
            </div>
            : <div class="noDrop" title="请选择支付方式">确认付款</div>
        }
      </div>
      {this.show && this.dialogDom()}
    </div>
  }

  /**
     * @description 根据地址生成二维码
     * @author Jinx
     * @date 2021-10-29 10:34:54
     * @param {string} url
     */
   qrCode(url: string): void {
    const qrcode = new QRCode(this.qrCodeUrl, {
      width: 300, //图像宽度
      height: 300, //图像高度
      colorDark: "#000000", //前景色
      colorLight: "#ffffff", //背景色
      typeNumber: 4,
      correctLevel: QRCode.CorrectLevel.H
      //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
    })
    qrcode.clear()
    qrcode.makeCode(url)
  }

  async pay(): Promise<void> {
    const obj: {id: number, totalFee: string} = {
      id: this.detailRow.id,
      totalFee: this.detailRow.payPrice
    }
    const { data } = await api.getStripeKey()
    switch (this.detailObj.payType) {
      case 1:
        // 支付宝支付
        if(document.body.clientWidth <= 900) {
          axios({
            url: '/mall-portal/payment/alipay',
            method: 'post',
            headers: {
              Authorization: getToken()
            },
            params:obj
          }).then(res => {
            const oDiv = document.createElement('divform')
            oDiv.innerHTML = res.data
            document.body.appendChild(oDiv)
            document.forms[0].acceptCharset='GBK';
            document.forms[0].submit();
          })
        } else {
          api.paymentPay({
            price: obj.totalFee,
            goodsId: obj.id,
            goodsName: this.smsSubjectObj.title,
            subject: this.smsSubjectObj.title,
            body: 'subject',
            quantity: 1
          }).then(res => {
            this.show = true
            this.$nextTick(() => this.qrCode(res.data))
            this.timer = setInterval(() => {
              api.wxPayPayStatus({outTradeNo: `subject-${obj.id}`}).then(res => {
                if(res.data === 1) {
                  ElMessage({type: 'error', message: '支付失败'})
                  clearInterval(this.timer as NodeJS.Timeout)
                  this.timer = {}
                  this.dialogClose()
                } else if(!res.data) {
                  this.show = false
                  clearInterval(this.timer as NodeJS.Timeout)
                  this.timer = {}
                  ElNotification({
                    type: 'success',
                    title: '成功',
                    message: '支付成功'
                  })
                  setTimeout(() => {
                    this.$router.push({
                      name: 'MineSubject'
                    })
                  }, 1000)
                }
              })
            },5000)
          })
        }
        break
      case 2:
        // 微信支付
        if(document.body.clientWidth <= 900) {
          api.wx_pay_other({
            id: obj.id,
            totalFee: obj.totalFee,
            type: 'sign_up',
            url: 'https://www.taosseract.com/mine/subject'
            // url: window.location.href
          }).then(res => {
            window.location.href = res.data
            // window.location.href = 'https://www.taosseract.com/mine/order'
          })
        } else {
          api.sign_up_pay_url(obj).then(({ data }) => {
            const img = arrayBufferToBase64(data)
            this.payImg = img
            this.show = true
            clearInterval(this.timer as NodeJS.Timeout)
            this.timer = setInterval(() => {
              api.wxPayPayStatus({outTradeNo: `subject-${obj.id}`}).then(res => {
                if(res.data === 1) {
                  ElMessage({type: 'error', message: '支付失败'})
                  clearInterval(this.timer as NodeJS.Timeout)
                  this.timer = {}
                  this.dialogClose()
                } else if(!res.data) {
                  this.show = false
                  clearInterval(this.timer as NodeJS.Timeout)
                  this.timer = {}
                  ElNotification({
                    type: 'success',
                    title: '成功',
                    message: '支付成功'
                  })
                  setTimeout(() => {
                    this.$router.push({
                      name: 'MineSubject'
                    })
                  }, 1000)
                }
              })
            },5000)
          })
        }
        break
      case 3:
      case 4:
      case 5:
          this.stripe = await loadStripe(data)
          this.items.email = '526771102@qq.com'
          this.items.orderId = obj.id
          this.items.orderSn = '' + obj.id
          this.items.payType = '测试',
          this.items.price = obj.totalFee as string,
          this.items.title = '测试'
          this.items.orderType = 'subject'
          this.return_id = obj.id
          this.initialize()
        break
      default:
    }
  }

  // 获取付款意图并捕获客户端机密
  async initialize() {
    try {
      this.payLoading = true
      const { data } = await api.paymentRender(this.items)
      this.payLoading = false
      this.show = true
      this.$nextTick(() => {
        const {clientSecret} = JSON.parse(data)
        const appearance = {
          theme: 'stripe',
        }
        this.elements = this.stripe.elements({ appearance, clientSecret }) as any

        const paymentElement = this.elements.create("payment")
        paymentElement.mount("#payment-element")
      })
    } catch (error) {
      this.payLoading = false
      if(error) {
        ElNotification({
          type: 'error',
          message: '订单生成失败，请稍后再试'
        })
        return
      }
    }
  }

  dialogClose(): void {
    clearInterval(this.timer as NodeJS.Timeout)
    this.timer = {}
    this.show = false
    this.payImg = ''
  }

  async handleSubmit(e: Event): Promise<any> {
    e.preventDefault()
    this.setLoading(true)
    const return_url = window.location.href + '&return_id=' + this.return_id
    const { error } = await this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url
      },
    })
    if (error.type === "card_error" || error.type === "validation_error") {
      this.showMessage(error.message)
    } else {
      this.showMessage('一个意料之外的问题发生了')
    }
    this.setLoading(false)
  }

  // ------- 用户界面助手 -------

  showMessage(messageText: string | any): void {
    const messageContainer = document.querySelector("#payment-message") as Element

    messageContainer.classList.remove("hidden")
    messageContainer.textContent = messageText

    setTimeout(function () {
      messageContainer.classList.add("hidden")
      messageText.textContent = ""
    }, 4000)
  }

  // 在付款提交时显示微调器
  setLoading(isLoading: boolean) {
    if (isLoading) {
      // 禁用按钮并显示微调器
      const submit = document.querySelector("#submit") as ButtonHTMLAttributes
      submit.disabled = true
      document.querySelector("#spinner")?.classList.remove("hidden")
      document.querySelector("#button-text")?.classList.add("hidden")
    } else {
      const submit = document.querySelector("#submit") as ButtonHTMLAttributes
      submit.disabled = false
      document.querySelector("#spinner")?.classList.add("hidden")
      document.querySelector("#button-text")?.classList.remove("hidden")
    }
  }

  dialogDom(): JSX.Element {
    return <el-dialog
      title=""
      v-model={ this.show }
      width="400px"
      before-close={ this.dialogClose }
      close-on-click-modal={false}
      custom-class="subject-signup-dialog"
    >
      {[3, 4, 5].includes(this.detailObj.payType as number) && <form id="payment-form">
        <div id="payment-element">
        </div>
        <div id="submit" onClick={this.handleSubmit}>
          <div class="spinner hidden" id="spinner"></div>
          <span id="button-text">立即支付</span>
        </div>
        <div id="payment-message" class="hidden"></div>
      </form>}
      <div ref="qrCodeUrl"></div>
      <img src={this.payImg} alt="" />
    </el-dialog>
  }

  created(): void {
    if(this.$route.query?.redirect_status) {
      const id = this.$route.query.return_id as string
      switch (this.$route.query.redirect_status) {
        case "succeeded":
          api.signUpSignUpCallBack({id})
          ElNotification({
            type: 'success',
            title: '成功',
            message: '支付成功'
          })
          setTimeout(() => {
            this.$router.push({
              name: 'MineSubject'
            })
          }, 1000)
          return
        case "processing":
          ElMessage({
            type: 'error',
            message: '您的付款正在处理中'
          })
          return
        case "requires_payment_method":
          ElMessage({
            type: 'error',
            message: '您的付款未成功，请重试'
          })
          return
        default:
          ElMessage({
            type: 'error',
            message: '出了些问题'
          })
          return
      }
    }
  }

  render(): JSX.Element {
    return <div class="subject-signup">
      {!this.$route.query?.get_id && <BreadCrumbs />}
      {
        this.type === 'detail'
          ? this.detailDom()
          : <el-row gutter={30} class="subject-signup-main">
            <el-col xs={24} sm={24} md={14} lg={14} xl={14}>
              <div class="subject-signup-main-left">
                <div class="signup-info">
                  <img src={this.smsSubjectObj.coverImg} alt="" />
                  <div>{this.smsSubjectObj.title}</div>
                </div>
                <div class="signup-title">主讲：{this.tutorObj.title}</div>
                <div class="signup-time"><i class="el-icon-date"/>
                  {parseTime(new Date(this.smsSubjectObj.signUpStartTime as never).getTime(), '{y}.{m}.{d}')}
                    -
                  {parseTime(new Date(this.smsSubjectObj.signUpEndTime as never).getTime(), '{y}.{m}.{d}')}
                </div>
                <div class="signup-label"><span>课程内容</span></div>
                <p class="signup-content">{this.smsSubjectObj.introduce}</p>
                {
                  this.smsSubjectObj.title
                    && <div class="signup-fun">
                      <share-network
                          network="facebook"
                          url={window.location.href}
                          title={this.smsSubjectObj.title}
                          description={this.smsSubjectObj.introduce}
                        >
                        <div class="facebook"/>
                      </share-network>
                      <div class="wechat" onClick={() => sharer(2)}/>
                      <share-network
                          network="whatsapp"
                          url={window.location.href}
                          title={this.smsSubjectObj.title}
                          description={this.smsSubjectObj.introduce}
                        >
                        <div class="phone"/>
                      </share-network>
                    </div>
                }
              </div>
            </el-col>
            <el-col xs={24} sm={24} md={10} lg={10} xl={10}>
              <div class="subject-signup-main-right">
                <div class="right-title">报名信息</div>
                <el-form
                  ref="form"
                  class="mine_site-basic"
                  model={this.subjectForm}
                  rules={this.formRules}
                  label-width="100px"
                  label-position="top"
                >
                  <el-form-item label="姓名" prop="memberName">
                    <el-input v-model={this.subjectForm.memberName} clearable placeholder="请输入姓名" />
                  </el-form-item>
                  <el-form-item label="身份证" prop="cardId">
                    <el-input v-model={this.subjectForm.cardId} clearable placeholder="请输入身份证" />
                  </el-form-item>
                  <el-form-item label="手机号码" prop="phone">
                    <el-input v-model={this.subjectForm.phone} clearable placeholder="请输入手机号码" />
                  </el-form-item>
                  <el-form-item label="电邮" prop="email">
                    <el-input v-model={this.subjectForm.email} clearable placeholder="请输入电邮" />
                  </el-form-item>
                </el-form>
                <div class="right-bottom">
                  <div>HK$ {this.smsSubjectObj.price}</div>
                  <div onClick={() => debounceClick(this.submit)}>
                    {this.loading && <i class="el-icon-loading"/>}
                    <span style="margin-left: 4px">立即报名</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
      }
    </div>
  }

  mounted(): void {
    this.getDetail()
  }
}

